import axiosClient from "../axiosClient";

export function getWeeklyTopics() {
    return axiosClient.get("/Weekly-trivia");
}

export function getWeeklyQuestions(data) {
    return axiosClient.post("/Weekly-trivia/quiz", JSON.stringify(data));
}

export function submitWeeklyAnswer(data) {
    return axiosClient.post("/Weekly-trivia/submit-answer", JSON.stringify(data));
}

export function getWeeklyResult() {
    return axiosClient.get("/Weekly-trivia/result");
}