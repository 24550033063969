import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { getWeeklyQuestions, submitWeeklyAnswer, getWeeklyResult } from '../../ApiCalls/WeeklyTrivia/api_weekly_trivia';
import {
    Typography,
    Box,
    LinearProgress as MuiLinearProgress,
    Container,
    Button,
    Fade,
    Zoom,
    Paper,
    Avatar,
    CircularProgress
} from '@mui/material';
import {
    AccessTime as ClockIcon,
    EmojiEvents as TrophyIcon,
    School as TopicIcon,
    Check as CheckIcon,
    Close as CloseIcon
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import confetti from 'canvas-confetti';
import { styled } from '@mui/system';

import TriviaPopup from './TriviaPopup';

const WeeklyTrivia = () => {
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState(7);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [correctAnswer, setCorrectAnswer] = useState(null);
    const [isAnswerRevealed, setIsAnswerRevealed] = useState(false);
    const [message, setMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [quizPassed, setQuizPassed] = useState('');
    const [isCorrect, setIsCorrect] = useState(null);
    const [animateQuestion, setAnimateQuestion] = useState(true);
    const [score, setScore] = useState(0);
    const [loading, setLoading] = useState(true);
    const [currentTheme, setCurrentTheme] = useState('blue');
    const navigate = useNavigate();
    const confettiCanvas = useRef(null);
    const glowRef = useRef(null);
    const bubblesRef = useRef([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectedTopic = decodeURIComponent(searchParams.get('category') || '');

    const themes = {
        blue: {
            primary: '#3a86ff',
            secondary: '#4361ee',
            accent: '#38b6ff',
            gradient: 'linear-gradient(135deg, #111133 0%, #2a2a5c 100%)',
            glow: 'rgba(56, 182, 255, 0.05)',
            glowShadow: 'rgba(56, 182, 255, 0.1)'
        },
        golden: {
            primary: '#FFD700',
            secondary: '#DAA520',
            accent: '#FFC125',
            gradient: 'linear-gradient(135deg, #332911 0%, #5c4a2a 100%)',
            glow: 'rgba(255, 215, 0, 0.05)',
            glowShadow: 'rgba(255, 215, 0, 0.1)'
        },
        green: {
            primary: '#4AAF57',
            secondary: '#3D8C47',
            accent: '#5DC76B',
            gradient: 'linear-gradient(135deg, #1F382A 0%, #4AAF57 100%)',
            glow: 'rgba(74, 175, 87, 0.05)',
            glowShadow: 'rgba(74, 175, 87, 0.1)'
        },
        purple: {
            primary: '#8B6BA4',
            secondary: '#735D89',
            accent: '#A794BB',
            gradient: 'linear-gradient(135deg, #2D2438 0%, #584A72 100%)',
            glow: 'rgba(139, 107, 164, 0.05)',
            glowShadow: 'rgba(139, 107, 164, 0.1)'
        },
        pink: {
            primary: '#C76B98',
            secondary: '#A65B80',
            accent: '#E18AAF',
            gradient: 'linear-gradient(135deg, #382832 0%, #8E4E6E 100%)',
            glow: 'rgba(199, 107, 152, 0.05)',
            glowShadow: 'rgba(199, 107, 152, 0.1)'
        },
        coral: {
            primary: '#FF7F6B',
            secondary: '#E57060',
            accent: '#FF9B8C',
            gradient: 'linear-gradient(135deg, #383028 0%, #A15244 100%)',
            glow: 'rgba(255, 127, 107, 0.05)',
            glowShadow: 'rgba(255, 127, 107, 0.1)'
        }
    };

    const getThemeColors = () => themes[currentTheme];

    useLayoutEffect(() => {
        document.querySelector("meta[name='theme-color']").setAttribute("content", getThemeColors().primary);
        document.title = `${selectedTopic || 'Weekly'} Trivia | Ding`;

        confettiCanvas.current = document.createElement('canvas');
        confettiCanvas.current.style.position = 'fixed';
        confettiCanvas.current.style.top = '0';
        confettiCanvas.current.style.left = '0';
        confettiCanvas.current.style.width = '100%';
        confettiCanvas.current.style.height = '100%';
        confettiCanvas.current.style.pointerEvents = 'none';
        confettiCanvas.current.style.zIndex = '1000';
        document.body.appendChild(confettiCanvas.current);

        const bubbleContainer = document.createElement('div');
        bubbleContainer.style.position = 'fixed';
        bubbleContainer.style.top = '0';
        bubbleContainer.style.left = '0';
        bubbleContainer.style.width = '100%';
        bubbleContainer.style.height = '100%';
        bubbleContainer.style.overflow = 'hidden';
        bubbleContainer.style.zIndex = '-1';
        bubbleContainer.style.background = getThemeColors().gradient;
        document.body.appendChild(bubbleContainer);

        const bubbleCount = 25;
        for (let i = 0; i < bubbleCount; i++) {
            const bubble = document.createElement('div');
            const size = Math.random() * 80 + 20;
            const opacity = Math.random() * 0.08 + 0.02;

            bubble.style.position = 'absolute';
            bubble.style.width = `${size}px`;
            bubble.style.height = `${size}px`;
            bubble.style.borderRadius = '50%';

            const themeColors = themes[currentTheme];
            const color = themeColors.primary;
            const [r, g, b] = hexToRgb(color);

            const colorVariation = 30;
            const rVar = Math.max(0, Math.min(255, r + (Math.random() * colorVariation - colorVariation / 2)));
            const gVar = Math.max(0, Math.min(255, g + (Math.random() * colorVariation - colorVariation / 2)));
            const bVar = Math.max(0, Math.min(255, b + (Math.random() * colorVariation - colorVariation / 2)));

            bubble.style.backgroundColor = `rgba(${rVar}, ${gVar}, ${bVar}, ${opacity})`;
            bubble.style.boxShadow = `0 0 ${Math.floor(size / 4)}px ${themeColors.accent}40`;

            bubble.style.left = `${Math.random() * 100}%`;
            bubble.style.top = `${Math.random() * 100}%`;
            bubble.style.transform = 'scale(0)';
            bubble.style.transition = 'transform 0.5s ease';

            bubbleContainer.appendChild(bubble);
            bubblesRef.current.push({
                element: bubble,
                x: Math.random() * 100,
                y: Math.random() * 100,
                speedX: Math.random() * 0.1 - 0.05,
                speedY: Math.random() * 0.1 - 0.05,
                size,
                pulse: Math.random() * 2000 + 1000,
                pulseOffset: Math.random() * 1000
            });
        }

        const animateBubbles = () => {
            const now = Date.now();

            bubblesRef.current.forEach(bubble => {
                bubble.x += bubble.speedX;
                bubble.y += bubble.speedY;

                if (bubble.x < -10) bubble.x = 110;
                if (bubble.x > 110) bubble.x = -10;
                if (bubble.y < -10) bubble.y = 110;
                if (bubble.y > 110) bubble.y = -10;

                // Subtle pulsing effect using sin wave
                const pulseScale = 0.15;
                const baseScale = 1.0;
                const pulseFactor = Math.sin((now + bubble.pulseOffset) / bubble.pulse) * pulseScale + baseScale;

                bubble.element.style.transform = `scale(${pulseFactor})`;
                bubble.element.style.left = `${bubble.x}%`;
                bubble.element.style.top = `${bubble.y}%`;
            });

            bubbleAnimationRef.current = requestAnimationFrame(animateBubbles);
        };

        const bubbleAnimationRef = { current: null };
        bubbleAnimationRef.current = requestAnimationFrame(animateBubbles);

        return () => {
            document.body.removeChild(confettiCanvas.current);
            document.body.removeChild(bubbleContainer);
            if (bubbleAnimationRef.current) {
                cancelAnimationFrame(bubbleAnimationRef.current);
            }
        };
    }, [selectedTopic, currentTheme]);

    useEffect(() => {
        setLoading(true);
        getWeeklyQuestions({ category: selectedTopic }).then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            let data = response.data;

            if (data.questions != null) {
                setQuestions(data.questions);
            } else {
                setMessage(data.message || "No questions available for this topic.");
            }
            setLoading(false);
        }).catch(error => {
            if (error.response) {
                setMessage(error.response.data.message || "An error occurred. Please try again.");
            } else {
                setMessage("An error occurred while fetching questions.");
            }
            setLoading(false);
        });
    }, [selectedTopic]);

    useEffect(() => {
        if (!isAnswerRevealed && questions.length > 0) {
            const timer = setInterval(() => {
                setTimeRemaining((prev) => {
                    if (prev <= 0) {
                        clearInterval(timer);
                        handleTimeout();
                        return 0;
                    }
                    return prev - 0.1;
                });
            }, 100);

            return () => clearInterval(timer);
        }
    }, [currentQuestionIndex, isAnswerRevealed, questions.length]);

    useEffect(() => {
        if (questions.length > 0) {
            setAnimateQuestion(true);
            // Randomly THEME selection
            const themeKeys = Object.keys(themes);
            const randomTheme = themeKeys[Math.floor(Math.random() * themeKeys.length)];
            setCurrentTheme(randomTheme);
        }
    }, [currentQuestionIndex, questions.length]);

    const triggerConfetti = () => {
        const colors = currentTheme === 'blue'
            ? ['#3a86ff', '#4361ee', '#38b6ff', '#7209b7', '#3f37c9']
            : ['#FFD700', '#DAA520', '#FFC125', '#B8860B', '#CD853F'];

        const myConfetti = confetti.create(confettiCanvas.current, {
            resize: true,
            useWorker: true
        });

        myConfetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
            colors: colors
        });
    };

    const triggerGlowEffect = () => {
        if (glowRef.current) {
            glowRef.current.style.opacity = '1';
            setTimeout(() => {
                if (glowRef.current) {
                    glowRef.current.style.opacity = '0';
                }
            }, 700);
        }
    };

    const handleTimeout = () => {
        if (!isAnswerRevealed) {
            const mockResponse = { is_correct: false, correct_answer: "" };
            handleAnswerResponse(mockResponse);
        }
    };

    const handleAnswerClick = async (answer, currentQuestionId) => {
        if (isAnswerRevealed) return;
        setSelectedAnswer(answer);

        try {
            const response = await submitWeeklyAnswer({
                trivia_id: currentQuestionId,
                user_answer: answer
            });

            setIsCorrect(response.data.is_correct);

            if (response.data.is_correct) {
                setScore(prev => prev + 1);
                triggerConfetti();
                triggerGlowEffect();
            }

            handleAnswerResponse({
                is_correct: response.data.is_correct,
                correct_answer: response.data.correct_answer
            });

            if (response.message) {
                setMessage(response.message);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || "An error occurred while submitting your answer.";
            setMessage(errorMessage);
            setIsCorrect(false);

            handleAnswerResponse({
                is_correct: false,
                correct_answer: answer
            });
        }
    };

    const handleAnswerResponse = (response) => {
        setCorrectAnswer(response.correct_answer);
        setIsAnswerRevealed(true);

        setTimeout(() => {
            if (currentQuestionIndex < questions.length - 1) {
                setAnimateQuestion(false);
                setTimeout(() => {
                    setCurrentQuestionIndex(prev => prev + 1);
                    setTimeRemaining(7);
                    setSelectedAnswer(null);
                    setCorrectAnswer(null);
                    setIsAnswerRevealed(false);
                    setIsCorrect(null);
                    
                    // Reset button styles
                    const answerButtons = document.querySelectorAll('[role="button"]');
                    answerButtons.forEach(button => {
                        button.style.removeProperty('border-color');
                        button.style.removeProperty('box-shadow');
                        button.style.borderColor = 'rgba(100, 150, 255, 0.2)';
                    });
                }, 300);
            } else {
                handleTriviaCompletion();
            }
        }, 300);
    };

    const handleTriviaCompletion = async () => {
        try {
            const resultResponse = await getWeeklyResult();
            if (resultResponse.data.message === "Trivia result calculated successfully.") {
                const resultMessage = `
                    Correct Answers: ${resultResponse.data.correct_answers}/${resultResponse.data.total_questions}
                    ${resultResponse.data.passed ? "Congratulations! You passed!\nYou can now claim a free reward!" : "\nBetter luck next time!"}
                `;
                setQuizPassed(resultResponse.data.passed);
                setPopupMessage(resultMessage);

                if (resultResponse.data.passed) {
                    setTimeout(() => {
                        const myConfetti = confetti.create(confettiCanvas.current, {
                            resize: true,
                            useWorker: true
                        });

                        const end = Date.now() + 3000;

                        const interval = setInterval(() => {
                            if (Date.now() > end) {
                                clearInterval(interval);
                                return;
                            }

                            myConfetti({
                                particleCount: 50,
                                spread: 80,
                                origin: { x: Math.random(), y: Math.random() - 0.2 },
                                colors: currentTheme === 'blue'
                                    ? ['#3a86ff', '#4361ee', '#38b6ff', '#7209b7', '#3f37c9']
                                    : ['#FFD700', '#DAA520', '#FFC125', '#B8860B', '#CD853F']
                            });
                        }, 200);
                    }, 500);
                }
            } else {
                setPopupMessage(resultResponse.data.message);
            }

            setShowPopup(true);
        } catch (error) {
            const errorMessage = error.response?.data?.message || "An error occurred while getting your results.";
            setPopupMessage(errorMessage);
            setShowPopup(true);
        }
    };

    const closePopup = () => {
        setShowPopup(false);
        if (quizPassed) {
            navigate('/redeem-weekly');
        } else {
            navigate('/rewards-new');
        }
    };

    const getAnswerButtonStyle = (answer) => {
        const themeColors = getThemeColors();
        const baseStyle = {
            background: 'rgba(30, 30, 60, 0.4)',
            backdropFilter: 'blur(8px)',
            height: 'auto',
            minHeight: '50px',
            width: '100%',
            margin: '0 auto',
            padding: '12px 18px',
            borderRadius: '12px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: 'white',
            fontSize: '16px',
            border: `1px solid ${currentTheme === 'blue' ? 'rgba(100, 150, 255, 0.2)' : 'rgba(255, 215, 0, 0.2)'}`,
            letterSpacing: '0.5px',
            transition: 'all 0.3s ease',
            boxShadow: '0 4px 20px rgba(0,0,0,0.15)',
            textTransform: 'none',
            textAlign: 'left',
            position: 'relative',
            '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: `0 8px 25px ${currentTheme === 'blue' ? 'rgba(58, 134, 255, 0.2)' : 'rgba(255, 215, 0, 0.2)'}`,
                background: 'rgba(40, 40, 70, 0.6)',
                borderColor: currentTheme === 'blue' ? 'rgba(100, 150, 255, 0.4)' : 'rgba(255, 215, 0, 0.4)',
            },
            '&:active': {
                transform: 'translateY(1px)',
                boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
            },
        };

        if (!isAnswerRevealed) return baseStyle;

        if (answer === correctAnswer) return {
            ...baseStyle,
            backgroundColor: currentTheme === 'blue' ? 'rgba(25, 95, 125, 0.7)' : 'rgba(125, 95, 25, 0.7)',
            borderColor: themeColors.accent,
            boxShadow: `0 0 15px ${themeColors.accent}80`,
        };

        if (answer === selectedAnswer && answer !== correctAnswer) return {
            ...baseStyle,
            backgroundColor: 'rgba(125, 25, 35, 0.7)',
            borderColor: '#ff5c8d',
            boxShadow: '0 0 15px rgba(255, 92, 141, 0.5)',
        };

        return {
            ...baseStyle,
            opacity: 0.6,
        };
    };

    const LinearProgress = styled(MuiLinearProgress)({
        height: 8,
        borderRadius: 4,
        backgroundColor: 'rgba(20, 20, 50, 0.3)',
        '& .MuiLinearProgress-bar': {
            background: `linear-gradient(90deg, ${getThemeColors().accent}, ${getThemeColors().primary})`,
            borderRadius: 4
        }
    });

    if (message) {
        return (
            <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Paper elevation={5} sx={{
                    padding: 4,
                    textAlign: 'center',
                    backgroundColor: 'rgba(20, 20, 50, 0.7)',
                    backdropFilter: 'blur(10px)',
                    color: 'white',
                    borderRadius: '20px',
                    border: '1px solid rgba(100, 150, 255, 0.1)'
                }}>
                    <Typography variant="h5" gutterBottom>{message}</Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/rewards-new')}
                        sx={{
                            mt: 2,
                            backgroundColor: getThemeColors().accent,
                            '&:hover': {
                                backgroundColor: getThemeColors().accent + 'DD'
                            }
                        }}
                    >
                        Back to Rewards
                    </Button>
                </Paper>
            </Container>
        );
    }

    if (loading) {
        return (
            <Container maxWidth={false} disableGutters sx={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: getThemeColors().gradient,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                overflow: 'hidden'
            }}>
                <Paper elevation={6} sx={{
                    position: 'relative',
                    zIndex: 1,
                    textAlign: 'center',
                    background: 'rgba(20, 20, 50, 0.6)',
                    backdropFilter: 'blur(10px)',
                    padding: '30px',
                    borderRadius: '20px',
                    boxShadow: '0 15px 35px rgba(0, 0, 0, 0.4)',
                    border: '1px solid rgba(100, 150, 255, 0.1)',
                    width: { xs: '90%', sm: '400px' }
                }}>
                    <Avatar
                        sx={{
                            bgcolor: 'rgba(20, 20, 60, 0.8)',
                            width: 70,
                            height: 70,
                            margin: '0 auto 20px',
                            boxShadow: `0 0 20px ${getThemeColors().accent}80`,
                            border: `2px solid ${getThemeColors().accent}`
                        }}
                    >
                        <TopicIcon sx={{ fontSize: 38, color: getThemeColors().accent }} />
                    </Avatar>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            color: 'white',
                            marginBottom: 3,
                            letterSpacing: '1px'
                        }}
                    >
                        {selectedTopic || "Weekly"} Trivia
                    </Typography>
                    <CircularProgress
                        size={60}
                        thickness={4}
                        sx={{
                            color: getThemeColors().accent,
                            mb: 3
                        }}
                    />
                    <Typography
                        variant="body1"
                        sx={{
                            color: 'rgba(255, 255, 255, 0.8)',
                            letterSpacing: '0.5px'
                        }}
                    >
                        Loading your questions...
                    </Typography>
                </Paper>
            </Container>
        );
    }

    const currentQuestion = questions[currentQuestionIndex];

    return (
        <Container
            disableGutters
            maxWidth={false}
            sx={{
                minHeight: '100vh',
                maxHeight: '100vh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                position: 'relative',
                paddingTop: { xs: 2, md: 3 },
                paddingBottom: { xs: 2, md: 3 },
                paddingLeft: { xs: 2, md: 4 },
                paddingRight: { xs: 2, md: 4 }
            }}
        >
            {/* Screen-wide glow effect */}
            <Box
                ref={glowRef}
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: getThemeColors().glow,
                    boxShadow: `inset 0 0 100px ${getThemeColors().glowShadow}`,
                    zIndex: 0,
                    opacity: 0,
                    transition: 'opacity 0.4s ease',
                    pointerEvents: 'none'
                }}
            />

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
                position: 'relative',
                zIndex: 1
            }}>
                <Fade in={true} timeout={800}>
                    <Paper elevation={3} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: 'rgba(20, 20, 50, 0.5)',
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '12px',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(100, 150, 255, 0.1)',
                    }}>
                        <TopicIcon sx={{ mr: 1, color: getThemeColors().accent }} />
                        <Typography sx={{
                            fontWeight: 500,
                            letterSpacing: '0.5px',
                        }}>
                            {selectedTopic || "Weekly Trivia"}
                        </Typography>
                    </Paper>
                </Fade>

                <Fade in={true} timeout={800}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Paper elevation={3} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'rgba(20, 20, 50, 0.5)',
                            color: 'white',
                            padding: '8px 16px',
                            borderRadius: '12px',
                            backdropFilter: 'blur(10px)',
                            border: '1px solid rgba(100, 150, 255, 0.1)',
                        }}>
                            <TrophyIcon sx={{ mr: 1, color: getThemeColors().accent }} />
                            <Typography sx={{
                                fontWeight: 500,
                                letterSpacing: '0.5px',
                            }}>
                                {score}/{questions.length}
                            </Typography>
                        </Paper>
                    </Box>
                </Fade>
            </Box>

            <Fade in={true} timeout={800}>
                <Box sx={{
                    mb: 3,
                    width: '100%',
                    position: 'relative',
                    zIndex: 1
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography sx={{
                            color: 'rgba(255, 255, 255, 0.8)',
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px'
                        }}>
                            <ClockIcon sx={{ mr: 0.5, fontSize: '16px', color: getThemeColors().accent }} />
                            Time Remaining
                        </Typography>
                        <Typography sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '14px'
                        }}>
                            {timeRemaining.toFixed(1)}s
                        </Typography>
                    </Box>
                    <LinearProgress
                        variant="determinate"
                        value={(timeRemaining / 7) * 100}
                    />
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 1,
                        mb: 2
                    }}>
                        <Typography sx={{
                            color: 'rgba(255, 255, 255, 0.7)',
                            fontSize: '14px'
                        }}>
                            Question {currentQuestionIndex + 1} of {questions.length}
                        </Typography>
                    </Box>
                </Box>
            </Fade>

            <Zoom in={animateQuestion} style={{ transitionDelay: '200ms' }}>
                <Paper elevation={6} sx={{
                    background: 'rgba(20, 20, 50, 0.6)',
                    backdropFilter: 'blur(15px)',
                    borderRadius: '20px',
                    padding: '25px',
                    mb: { xs: 3, md: 4 },
                    color: 'white',
                    letterSpacing: '0.5px',
                    textAlign: 'center',
                    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)',
                    border: '1px solid rgba(100, 150, 255, 0.15)',
                    position: 'relative',
                    zIndex: 1,
                    maxWidth: { sm: '700px' },
                    margin: '0 auto',
                    marginBottom: { xs: 3, md: 4 }
                }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 'bold',
                            lineHeight: 1.4,
                            padding: { xs: '0', md: '0 20px' }
                        }}
                    >
                        {currentQuestion.question}
                    </Typography>
                </Paper>
            </Zoom>

            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                overflow: 'auto',
                flex: 1,
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none',
                position: 'relative',
                zIndex: 1,
                maxWidth: { sm: '700px' },
                margin: '0 auto',
                width: '100%'
            }}>
                {['answer1', 'answer2', 'answer3', 'answer4'].map((answerKey, index) => (
                    <Zoom
                        in={animateQuestion}
                        key={answerKey}
                        style={{ transitionDelay: `${150 * index}ms` }}
                        timeout={500}
                    >
                        <Button
                            onClick={() => handleAnswerClick(currentQuestion[answerKey], currentQuestion.id)}
                            disabled={isAnswerRevealed}
                            sx={getAnswerButtonStyle(currentQuestion[answerKey])}
                        >
                            <Box component="span" sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '30px',
                                height: '30px',
                                minWidth: '30px',
                                backgroundColor: 'rgba(40, 40, 80, 0.4)',
                                borderRadius: '50%',
                                marginRight: '15px',
                                fontSize: '16px',
                                border: '1px solid rgba(100, 150, 255, 0.3)',
                                position: 'relative'
                            }}>
                                {isAnswerRevealed && currentQuestion[answerKey] === correctAnswer ? (
                                    <CheckIcon sx={{ color: getThemeColors().accent, fontSize: '18px' }} />
                                ) : isAnswerRevealed && currentQuestion[answerKey] === selectedAnswer ? (
                                    <CloseIcon sx={{ color: '#ff5c8d', fontSize: '18px' }} />
                                ) : (
                                    String.fromCharCode(65 + index)
                                )}
                            </Box>
                            {currentQuestion[answerKey]}
                        </Button>
                    </Zoom>
                ))}
            </Box>

            {showPopup &&
                <TriviaPopup
                    message={popupMessage}
                    onClose={closePopup}
                    passed={quizPassed}
                />
            }
        </Container>
    );
};

const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : [0, 0, 0];
};

export default WeeklyTrivia;