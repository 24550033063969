import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import { getDecryptMsisdn } from "../../ApiCalls/Auth/api_v1_auth";
import { useEffect, useState } from "react";
import Loader from '../Animations/Loader';
const useAuth = () => {
    // const cookies = new Cookies();
    let isTokenAvailable = localStorage.getItem("token");
    const current = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(current.getFullYear() + 1);
    let location = useLocation();
    const [loggedIn, setLoggedIn] = useState(null);
    const { REACT_APP_NDNC_HOST_NAME, REACT_APP_LOGIN_URL, REACT_APP_LOGIN_URL_NDNC } = process.env;
    const hostname = window.location.hostname;
    const src = new URLSearchParams(window.location.search).get("source");
    let ndnc = 0;
    if (hostname == REACT_APP_NDNC_HOST_NAME || (hostname != REACT_APP_NDNC_HOST_NAME && src == 1)) {
        ndnc = 1;
    }
    useEffect(() => {
        if (!sessionStorage.getItem("firstLoad")) {
            localStorage.clear();
            sessionStorage.setItem("firstLoad", "true");
        }
        const fetchData = async () => {
            let auth;
            let msisdn;
            try {
                const decoded = jwt_decode(isTokenAvailable);
                let u = decoded.userInfo['msisdn'];
                const authenticateUser = await getDecryptMsisdn({ encMsisdn: u });
                if ('access_token' in authenticateUser.headers) {
                    const accessToken = authenticateUser.headers['access_token'];
                    if (accessToken !== null && accessToken !== undefined) {
                        localStorage.setItem('token', accessToken);
                    }
                }
                auth = authenticateUser.data.data.suv;
                msisdn = authenticateUser.data.data.msisdn;
            } catch {
                auth = 0;
                msisdn = 0;
            }
            if (auth === 1 && msisdn.toString().length === 12) {
                // Both auth is 1 and msisdn length is 12
                setLoggedIn(true);
            }
            else {
                setLoggedIn(false);
            }
        };
        if (isTokenAvailable) {
            fetchData();
        } else {
            const redirect = new URLSearchParams(window.location.search).get("r");
            if (redirect === null) {
                const queryParameters = window.location.search; // Get all query parameters
                const redirectURL = process.env.REACT_APP_SESSION_URL + queryParameters + (queryParameters ? "&uuid=-1&s=" + ndnc : "?uuid=-1&s=" + ndnc);
                window.location.href = redirectURL;
                return;
            }
        }

    }, [location.pathname])
    return loggedIn
}


const ProtectedRoutes = () => {
    let location = useLocation();
    const cookies = new Cookies();
    const isAuth = useAuth();
    let isUuidExp = cookies.get('uid_exp');
    if (isAuth === null) // waiting..
    { return <Loader />; }

    if (location.pathname === "/share/watch") {
        return <Outlet />
    }

    const src = new URLSearchParams(window.location.search).get("source");
    const queryParameters = window.location.search; // Get all query parameters
    const finalParams = queryParameters + (queryParameters ? "&uuid=-1&s=" + src + "&exp=1" : "?uuid=-1&s=" + src + "&exp=1");
    const redirectURL = `/login${finalParams.toString()}`;

    return isAuth ? <Outlet /> : <Navigate to={redirectURL} />

}


export default React.memo(ProtectedRoutes);