import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import Loader from "../Animations/Loader";
import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { getEncMsiUnsub } from "../../ApiCalls/LoginAuthenticateApi/api_v1_login_validate";
import { getUser } from "../../ApiCalls/Profile/api_v1_profile";

function Unsub() {
    const [loading, setLoading] = useState(true);
    const cookies = new Cookies();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");
        document.title = "Unsubscribe | Ding";
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const accessToken = localStorage.getItem('token');
                const decoded = jwt_decode(accessToken);
                const msisdn = String(decoded.userInfo['msisdn']);


                let phone;
                if (msisdn.substring(0, 2) === "92") {
                    phone = msisdn
                } else {
                    getUser().then(response => {
                        const accessToken = response.headers['access_token'];
                        if (accessToken != null) {
                            localStorage.setItem('token', accessToken);
                        }

                        let data = response.data.data;
                        phone = data.msisdn
                    })
                        .catch(error => {
                            navigate('/login?r=1', { state: { unsubState: false } });
                        })
                }
                let uncData = { mobile: phone, domainName: "jazz_ding" }
                const encUnsub = await getEncMsiUnsub(uncData);
                return encUnsub.data.data;
            } catch (error) {
                navigate('/login?r=1', { state: { unsubState: false } });
            }
        }

        fetchData().then(msisdn => {
            if (msisdn) {
                logout();
                window.location.replace("https://unsubscribe.jazz.ding.pk/" + msisdn.mobile + "/" + msisdn.domainName);
            } else {
                navigate('/login?r=1', { state: { unsubState: false } });
            }
        });
    }, []);

    function logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('ctx');
        localStorage.removeItem('dx');
        localStorage.removeItem('time');
        cookies.remove('uid');
        cookies.remove('rmsid');
        const nextYear = new Date();
        const currentD = new Date();
        nextYear.setFullYear(currentD.getFullYear() + 1);
        // cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
        // localStorage.clear();
        // cookies.remove('uid_exp');
        // cookies.remove('uid');
        // cookies.remove('base');
        // cookies.remove('clickid');
        // cookies.remove('cpid');
        // cookies.remove('rmsid');
        // cookies.remove('source');
        // cookies.remove('tc');
        // cookies.remove('u');
    }

    return loading ? <Loader /> : null;
}

export default memo(Unsub);
