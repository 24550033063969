import React, { useEffect } from 'react';
import { Paper, Typography, Button, Fade, Box } from '@mui/material';
import { Close as CloseIcon, EmojiEvents as TrophyIcon } from '@mui/icons-material';

const TriviaPopup = ({ message, onClose, passed }) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const lines = message.split('\n').filter(line => line.trim() !== '');
    const [scoreText, resultText] = lines;

    return (
        <Fade in={true} timeout={800}>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    backdropFilter: 'blur(8px)',
                    zIndex: 9999,
                    padding: '20px'
                }}
            >
                <Paper
                    elevation={24}
                    sx={{
                        position: 'relative',
                        background: 'rgba(20, 20, 50, 0.8)',
                        backdropFilter: 'blur(20px)',
                        maxWidth: '400px',
                        width: '100%',
                        padding: '32px 24px',
                        borderRadius: '24px',
                        border: '1px solid rgba(255, 255, 255, 0.1)',
                        textAlign: 'center',
                        overflow: 'hidden',
                        animation: passed ? 'glow 2s infinite alternate' : 'none',
                        '@keyframes glow': {
                            '0%': {
                                boxShadow: '0 0 20px rgba(255, 215, 0, 0.2)'
                            },
                            '100%': {
                                boxShadow: '0 0 40px rgba(255, 215, 0, 0.4)'
                            }
                        }
                    }}
                >
                    <Button
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            minWidth: '40px',
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 255, 255, 0.2)'
                            }
                        }}
                    >
                        <CloseIcon />
                    </Button>

                    {passed && (
                        <Box sx={{
                            width: '80px',
                            height: '80px',
                            margin: '0 auto 24px',
                            backgroundColor: 'rgba(255, 215, 0, 0.1)',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            animation: 'float 3s infinite ease-in-out',
                            '@keyframes float': {
                                '0%, 100%': { transform: 'translateY(0px)' },
                                '50%': { transform: 'translateY(-10px)' }
                            }
                        }}>
                            <TrophyIcon sx={{ 
                                fontSize: 40, 
                                color: '#FFD700',
                                filter: 'drop-shadow(0 0 10px rgba(255, 215, 0, 0.5))'
                            }} />
                        </Box>
                    )}

                    <Typography
                        variant="h6"
                        sx={{
                            color: 'white',
                            fontWeight: 'bold',
                            marginBottom: 2
                        }}
                    >
                        {scoreText}
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            color: 'rgba(255, 255, 255, 0.8)',
                            marginBottom: 4
                        }}
                    >
                        {resultText}
                    </Typography>

                    <Button
                        onClick={onClose}
                        variant="contained"
                        sx={{
                            backgroundColor: passed ? '#FFD700' : 'rgba(255, 255, 255, 0.2)',
                            color: passed ? '#000' : '#fff',
                            padding: '12px 32px',
                            borderRadius: '12px',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                                backgroundColor: passed ? '#FFC107' : 'rgba(255, 255, 255, 0.3)',
                                transform: 'translateY(-2px)',
                                boxShadow: passed ? '0 8px 20px rgba(255, 215, 0, 0.3)' : 'none'
                            }
                        }}
                    >
                        {passed ? 'Claim Reward!' : 'Try Again'}
                    </Button>
                </Paper>
            </Box>
        </Fade>
    );
};

export default TriviaPopup;