import React from 'react';

import weeklyChallenge from '../../../images/v1/rewards/weeklyChallenge.svg'
import { useNavigate } from 'react-router-dom';

const WeeklyChallenge = () => {
  const navigate = useNavigate()

  const handleWeeklyTrivia = () => {
    navigate('/weekly-selection');
  }

  return (
    <div className="weekly-challenge" onClick={handleWeeklyTrivia}>
      <div className="shimmer"></div>
      <div className="sparkle-wrapper">
        <div className="sparkle"></div>
        <div className="sparkle delay-1"></div>
        <div className="sparkle delay-2"></div>
      </div>
      <div className="new-badge">NEW</div>
      <div className="blur left-blur"></div>
      <div className="blur bottom-blur"></div>
      <div className="blur right-blur"></div>
      <div className="content">
        <div className="text">
          <h2 className='stroke-color-black'>Weekly Challenge</h2>
          <p className='stroke-color-black'>Play the trivia and become winner of the week to get rewarded</p>
        </div>
        <div className="image">
          <img src={weeklyChallenge} alt="Reward box" />
        </div>
      </div>
      <style jsx>{`
        .weekly-challenge {
          background-color: #FBA8FF;
          border-radius: 20px;
          padding: 20px;
          position: relative;
          overflow: hidden;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
          position: relative;
          isolation: isolate;
        }

        .weekly-challenge:hover {
          transform: translateY(-4px);
        }

        .weekly-challenge:hover .glare {
          animation: glare 2s infinite;
        }

        .weekly-challenge:hover .flare {
          animation: flare 4s infinite;
        }

        .blur {
          position: absolute;
          filter: blur(30px);
          opacity: 0.3;
        }

        .left-blur {
          background-color: #5FF5EF;
          width: 100px;
          height: 100%;
          top: 0;
          left: -50px;
        }

        .bottom-blur {
          background-color: #D42F55;
          width: 100%;
          height: 60px;
          bottom: -30px;
          left: 0;
        }

        .right-blur {
          background-color: #812785;
          width: 60px;
          height: 100%;
          top: 0;
          right: -30px;
        }

        .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 1;
        }

        .text {
          flex: 1;
        }

        h2 {
          font-size: 22px;
          font-weight: bold;
          margin: 0 0 10px 0;
          color: #252525;
        }

        .image {
          margin-left: 20px;
        }

        .image img {
          width: 80px;
          height: 80px;
          object-fit: cover;
        }

        .new-badge {
          position: absolute;
          top: 12px;
          right: 12px;
          background: linear-gradient(135deg, #FF4B4B, #FF7676);
          color: white;
          padding: 4px 8px;
          border-radius: 12px;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.5px;
          z-index: 2;
          box-shadow: 0 2px 8px rgba(255, 75, 75, 0.3);
          animation: pulse 2s infinite;
        }

        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.05); }
          100% { transform: scale(1); }
        }

        .glare {
          position: absolute;
          width: 150%;
          height: 100%;
          background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
          );
          transform: translateX(-100%);
          animation: glare 5s infinite;
          z-index: 2;
          pointer-events: none;
        }

        .flare {
          position: absolute;
          width: 100px;
          height: 100px;
          background: radial-gradient(
            circle,
            rgba(255, 255, 255, 0.3) 0%,
            transparent 70%
          );
          border-radius: 50%;
          filter: blur(15px);
          animation: flare 8s infinite;
          z-index: 1;
          pointer-events: none;
        }

        @keyframes glare {
          0% { transform: translateX(-100%) }
          20% { transform: translateX(100%) }
          100% { transform: translateX(100%) }
        }

        @keyframes flare {
          0% { 
            transform: translate(-50%, -50%) scale(0.5);
            opacity: 0;
          }
          50% { 
            transform: translate(150%, 50%) scale(1);
            opacity: 1;
          }
          100% { 
            transform: translate(300%, -50%) scale(0.5);
            opacity: 0;
          }
        }

        .shimmer {
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
          background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
          );
          animation: shimmer 4s ease-in-out infinite;
          z-index: 2;
        }

        .sparkle-wrapper {
          position: absolute;
          inset: 0;
          overflow: hidden;
          z-index: 1;
          pointer-events: none;
        }

        .sparkle {
          position: absolute;
          background: radial-gradient(circle, white 0%, transparent 60%);
          border-radius: 50%;
          width: 20px;
          height: 20px;
          opacity: 0;
          pointer-events: none;
          animation: sparkle 3s ease-out infinite;
        }

        .delay-1 { animation-delay: 1.5s; }
        .delay-2 { animation-delay: 0.7s; }

        @keyframes shimmer {
          0% {
            transform: translateX(-150%) skewX(-20deg);
            opacity: 0;
          }
          25% {
            opacity: 1;
          }
          75% {
            opacity: 1;
          }
          100% {
            transform: translateX(150%) skewX(-20deg);
            opacity: 0;
          }
        }

        @keyframes sparkle {
          0% {
            transform: translate(20%, 20%) scale(0);
            opacity: 0;
          }
          15% {
            opacity: 0.8;
            transform: translate(60%, -30%) scale(1);
          }
          35% {
            opacity: 1;
            transform: translate(120%, -60%) scale(1.2);
          }
          45% {
            opacity: 0.8;
            transform: translate(180%, -90%) scale(1);
          }
          60% {
            opacity: 0;
            transform: translate(250%, -120%) scale(0);
          }
          100% {
            transform: translate(250%, -120%) scale(0);
            opacity: 0;
          }
        }

        .weekly-challenge:hover .sparkle {
          animation: sparkle 2s infinite;
        }
      `}</style>
    </div>
  );
};

export default WeeklyChallenge;