import React, { useState, useEffect } from 'react';
import hourglass from '../../../images/v1/rewards/hourglass.svg'

const DailyLogin = ({coins}) => {
  const [timeLeft, setTimeLeft] = useState(getTimeUntilMidnight());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeUntilMidnight());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function getTimeUntilMidnight() {
    const now = new Date();
    const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const diff = midnight - now;
    return {
      hours: Math.floor(diff / (1000 * 60 * 60)),
      minutes: Math.floor((diff / (1000 * 60)) % 60),
      seconds: Math.floor((diff / 1000) % 60)
    };
  }

  return (
    <div className="daily-login-reward">
      <div className="gradient-blur bottom-blur"></div>
      <div className="gradient-blur right-blur"></div>
      <div className="gradient-blur left-blur"></div>
      <div className="content">
        <div className="left-section">
          <h2 className='stroke-color-black'>Daily Log in Reward</h2>
          <div className="timer">
            <div className="time-unit">
              <div className="time-value">{String(timeLeft.hours).padStart(2, '0')}</div>
              <div className="time-label">hrs</div>
            </div>
            <div className="separator">:</div>
            <div className="time-unit">
              <div className="time-value">{String(timeLeft.minutes).padStart(2, '0')}</div>
              <div className="time-label">mins</div>
            </div>
            <div className="separator">:</div>
            <div className="time-unit">
              <div className="time-value">{String(timeLeft.seconds).padStart(2, '0')}</div>
              <div className="time-label">sec</div>
            </div>
          </div>
        </div>
        <div className="right-section">
          <img src={hourglass} alt="hourglass" className="hourglass" />
          <div className="reward">
            <div className="reward-amount">{coins}</div>
            <div className="reward-label">coins</div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .daily-login-reward {
          background-color: #E7FFA1;
          border-radius: 15px;
          padding: 20px;
          font-family: 'Open Sans', sans-serif;
          color: #000;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          position: relative;
          overflow: hidden;
          transition: transform 0.3s ease;
        }

        .daily-login-reward:hover {
          transform: translateY(-4px);
        }

        .gradient-blur {
          position: absolute;
          border-radius: 50%;
          opacity: 0.3;
          filter: blur(25px);
        }

        .bottom-blur {
          background-color: #D42F55;
          width: 100%;
          height: 100px;
          bottom: -50px;
          left: 0;
          right: 0;
        }

        .right-blur {
          background-color: #812785;
          width: 100px;
          height: 100%;
          top: 0;
          right: -50px;
        }

        .left-blur {
          background-color: #5FF5EF;
          width: 100px;
          height: 100%;
          top: 0;
          left: -50px;
        }

        .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 1;
        }

        h2 {
          font-size: 22px;
          font-weight: bold;
          margin: 0 0 10px 0;
        }

        .timer {
          display: flex;
          align-items: center;
        }

        .time-unit {
          background-color: rgba(37, 37, 37, 0.15);
          border-radius: 8px;
          padding: 8px;
          margin-right: 5px;
          text-align: center;
        }

        .time-value {
          font-size: 16px;
          font-weight: 600;
        }

        .time-label {
          font-size: 11px;
          font-weight: 600;
        }

        .separator {
          font-size: 22px;
          margin: 0 5px;
        }

        .right-section {
          display: flex;
          align-items: center;
          margin-top: 20px;
        }

        .hourglass {
          width: auto;
          height: 55px;
          margin-right: 10px;
        }

        .reward {
          text-align: right;
        }

        .reward-amount {
          font-size: 32px;
          font-weight: bold;
        }

        .reward-label {
          font-size: 14px;
          font-weight: 600;
        }
      `}</style>
    </div>
  );
};

export default DailyLogin;