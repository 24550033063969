import React, { useLayoutEffect, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import styles from '../../styles/login/login.module.css';
import bgImg from '../../images/v1/login/bg.avif'
import logo from '../../images/v1/login/logo.avif'
import logo_gamenow from '../../images/v1/login/logo_gamenow.avif'
import logo_ding from '../../images/v1/login/ding_logo.avif'
import logo_jazz from '../../images/v1/login/jazz logo.avif'
import PrivacyPolicy from './PrivacyPolicy';

const ThankYouSubscribe = () => {
    const cookies = new Cookies();
    const [ShowPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const currentD = new Date();
    const nextYear = new Date();
    nextYear.setFullYear(currentD.getFullYear() + 1);
    let navigate = useNavigate();
    useLayoutEffect(() => {
        localStorage.setItem('token', "QiLCJhbGciOiJ");
        cookies.set('uid_exp', 1, { path: '/', expires: nextYear });
        document.body.style.backgroundColor = "#F3BB03";
        document.body.style.maxWidth = "500px";
        document.body.style.margin = "0 auto";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#252525");
        document.title = "Login | Ding";
    }, []);

    useEffect(() => {
        loginInfo();
    }, []);




    const loginInfo = (event) => {
        const a = new URLSearchParams(window.location.search).get("a");
        const guuid = new URLSearchParams(window.location.search).get("uuid");
        const msisdn = new URLSearchParams(window.location.search).get("user");
        const source = new URLSearchParams(window.location.search).get("source");
        const r = new URLSearchParams(window.location.search).get("r");
        const j = new URLSearchParams(window.location.search).get("j");
        if (a == 1 && source == 9 && j) {
            localStorage.setItem('token', j);
            cookies.set('uid_exp', 0, { path: '/', expires: nextYear });
            cookies.set('u', msisdn, { path: '/', expires: nextYear });
            cookies.set('uid', guuid, { path: '/', expires: nextYear });

            setTimeout(
                () =>
                    window.location.replace('/home'),
                // navigate('/home'),
                3000
            );
        } else {
            navigate('/login');
        }

    }



    function handlePrivacyPolicy() {
        setShowPrivacyPolicy(!ShowPrivacyPolicy);
    }

    return (
        <>
            <img src={logo_ding} alt="ding" className={styles.logoTopLeft} />
            <img src={logo_jazz} alt="jazz" className={styles.logoTopRight} />
            <img src={logo_gamenow} alt="jazz" className={styles.logoBottomRight} style={{ zIndex: "-1" }} />
            <img src={bgImg} alt="." style={{ position: "fixed", height: "100%", width: "100%", zIndex: "-3", maxWidth: "700px", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
            <main className={styles.main + ' d-flex justify-content-center flex-column align-items-center'}>
                {/* <h1 className={styles.logoText}>ding</h1> */}
                <img src={logo} alt="..." className={styles.logoImg} />
                <section className={styles.formSection + ` d-flex justify-content-center flex-column align-items-center mt-30`}>
                    {/* <p className={styles.loginFormTitle + ' mb-5i'}>Enter your mobile number</p> */}
                    <h3 className="text-white text-center fs-20">Thank you for subscribing!</h3>
                    <h4 className="text-white text-center fs-18">Your subscription was successfully completed.</h4>


                    <div className='wmx-350 mt-4'>
                        {!ShowPrivacyPolicy ? <p className={`${styles.consent} mb-2 cursor-pointer`} onClick={handlePrivacyPolicy}>Privacy Policy</p> :
                            <PrivacyPolicy handlePrivacyPolicy={handlePrivacyPolicy} />}
                    </div>
                    <p style={{ marginTop: '5px', fontSize: '12px' }}>Service charges Rs.10+T/Day</p>
                </section>

            </main >
        </>
    );
}

export default React.memo(ThankYouSubscribe);