import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Fade,
    IconButton
} from '@mui/material';
import {
    School as SchoolIcon,
    Public as WorldIcon,
    Lightbulb as KnowledgeIcon,
    Park as EnvironmentIcon,
    Rocket as SpaceIcon,
    Memory as TechIcon,
} from '@mui/icons-material';
import { getWeeklyTopics } from '../../ApiCalls/WeeklyTrivia/api_weekly_trivia';

const WeeklyTriviaSelector = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const gradients = [
        'linear-gradient(135deg, #FF6B6B 0%, #FF8E8E 100%)',
            'linear-gradient(135deg, #4FACFE 0%,rgb(2, 207, 218) 100%)',
            'linear-gradient(135deg, #43E97B 0%,rgb(49, 223, 191) 100%)',
            'linear-gradient(135deg, #FA709A 0%, #FEE140 100%)',
            'linear-gradient(135deg, #6B73FF 0%, #000DFF 100%)',
            'linear-gradient(135deg, #FFA62E 0%, #EA4D2C 100%)',
            'linear-gradient(135deg, #A18CD1 0%,rgb(196, 150, 183) 100%)',
    ];

    const getGradient = (index) => gradients[index % gradients.length];

    const handleWeeklyTrivia = async () => {
        try {
            const response = await getWeeklyTopics();
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            if (response.data.categories != null) {
                setCategories(response.data.categories);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        document.title = 'Weekly Trivia Selection | Ding';
        handleWeeklyTrivia();
    }, []);

    const handleTopicSelection = (category) => {
        const encodedCategory = encodeURIComponent(category);
        navigate(`/weekly-trivia?category=${encodedCategory}`);
    };

    const getTopicIcon = (topic) => {
        const iconMap = {
            'History, Geography & Culture': WorldIcon,
            'General Knowledge': KnowledgeIcon,
            'Earth & Environmental Sciences': EnvironmentIcon,
            'Astronomy & Space': SpaceIcon,
            'Technology and Engineering': TechIcon,
        };
        
        const IconComponent = iconMap[topic] || SchoolIcon;
        return <IconComponent sx={{ fontSize: 32 }} />;
    };

    return (
        <Box sx={{
            minHeight: '100vh',
            padding: { xs: 2, sm: 3 },
        }}>
            <Grid container spacing={2}>
                {(loading ? Array(5).fill({}) : categories)
                    .map((item, index) => (
                        <Grid item xs={12} sm={6} lg={4} key={item.category || index}>
                            <Fade in={!loading} timeout={300 + (index * 100)}>
                                <Paper
                                    onClick={() => item.category && handleTopicSelection(item.category)}
                                    sx={{
                                        background: getGradient(index),
                                        borderRadius: '16px',
                                        padding: 2,
                                        color: 'white',
                                        cursor: 'pointer',
                                        transition: 'all 0.3s ease',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        height: '110px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        '&:hover': {
                                            transform: 'translateY(-4px)',
                                            '& .icon-box': {
                                                transform: 'scale(1.1)',
                                            }
                                        },
                                        '&::before': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: 'radial-gradient(circle at 90% -10%, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 60%)',
                                        }
                                    }}
                                >
                                    {loading ? (
                                        <Box sx={{ height: '100%', background: '#e2e8f0', opacity: 0.3 }} />
                                    ) : (
                                        <>
                                            <Box className="icon-box" sx={{ 
                                                transition: 'transform 0.3s ease',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1.5 
                                            }}>
                                                {getTopicIcon(item.category)}
                                                <Typography 
                                                    variant="h6" 
                                                    sx={{ 
                                                        fontWeight: 600,
                                                        fontSize: '1rem',
                                                        lineHeight: 1.2
                                                    }}
                                                >
                                                    {item.category}
                                                </Typography>
                                            </Box>
                                            <IconButton 
                                                sx={{ 
                                                    alignSelf: 'flex-end',
                                                    backgroundColor: 'rgba(255,255,255,0.2)',
                                                    color: 'white',
                                                    padding: '4px',
                                                    '&:hover': {
                                                        backgroundColor: 'rgba(255,255,255,0.3)'
                                                    }
                                                }}
                                            >
                                                <SchoolIcon sx={{ fontSize: 18 }} />
                                            </IconButton>
                                        </>
                                    )}
                                </Paper>
                            </Fade>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default WeeklyTriviaSelector;