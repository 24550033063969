import React, { useLayoutEffect, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import styles from '../../styles/redeem/redeemBody.module.css';
import { getItemPrice, getRedeemItem } from "../../ApiCalls/Redeem/api_v1_redeem";
import RedeemFormWeekly from "./RedeemFormWeekly";
import SfxMainAudio from "../AutoPlayAudio/SfxMainAudio";
import SubscribeConsentForm from './SubscribeConsentForm';
import Footer from "../footer/Footer";

import pubg from '../../images/v1/redeem/red_pubg.png';
import fortnite from '../../images/v1/redeem/red_fortnite.jpg';
import callofduty from '../../images/v1/redeem/red_callofduty.png';
import clashofclans from '../../images/v1/redeem/red_coc.jpg';
import clashroyale from '../../images/v1/redeem/red_clash_royale.jpg';
import yallaludo from '../../images/v1/redeem/red_yalla_ludo.png';
import minecraft from '../../images/v1/redeem/red_minecraft.jpg';
import freefire from '../../images/v1/redeem/red_free_fire.jpg';

function RedeemBodyWeekly() {
    const navigate = useNavigate();
    const [redeemItems, setRedeemItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeButtonId, setActiveButtonId] = useState(null);
    const [insufficientMsg, setInsufficientMsg] = useState(false);
    const [showForm, setShowForm] = useState({
        formConsentShow: false,
        formShow: false,
        resp: "",
        item: "",
        key: "",
        name: "",
        tel: "",
        uuid: "",
    });

    const imageMappings = {
        pubg: pubg,
        fortnite: fortnite,
        callofduty: callofduty,
        clashoflcans: clashofclans,
        clashroyale: clashroyale,
        yallaludo: yallaludo,
        minecraft: minecraft,
        freefire: freefire,
    };

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#191919";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#191919");
        document.title = "Redeem | Ding";
    }, []);

    useEffect(() => {
        fetchRedeemItems();
    }, []);

    const fetchRedeemItems = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await getItemPrice();
            if (response.data && response.data.data) {
                const items = Object.entries(response.data.data).map(([key, value], index) => ({
                    id: index + 1,
                    name: getBaseTitle(key),
                    image: imageMappings[key.split(/\d+/)[0]] || pubg,
                    apiKey: key,
                    description: value.replace("<br />", "\n"),
                }));
                setRedeemItems(items);
            } else {
                throw new Error('Invalid data structure');
            }
        } catch (error) {
            console.error("Error fetching redeem items:", error);
            setError("Failed to load redeem items. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const getBaseTitle = (key) => {
        if (key.includes("pubg")) return "Battlegrounds";
        if (key.includes("fortnite")) return "Fortnite";
        if (key.includes("callofduty")) return "Call of Duty";
        if (key.includes("clashoflcans")) return "Clash of Clans";
        if (key.includes("clashroyale")) return "Clash Royale";
        if (key.includes("yallaludo")) return "Yalla Ludo";
        if (key.includes("minecraft")) return "Minecraft";
        if (key.includes("freefire")) return "Free Fire";
        return key;
    };

    const handleClick = async (e) => {
        const value = e.target.getAttribute('value');
        setActiveButtonId(Number(value));

        try {
            const response = await getRedeemItem({ item: value });
            const accessToken = response.headers['access_token'];
            if (accessToken) {
                localStorage.setItem('token', accessToken);
            }

            if (response.data.message === "Ko" && response.data.data === "free trial") {
                setShowForm({ ...showForm, formConsentShow: true });
            } else if (response.data.message === "Ko" && response.status === 200) {
                setInsufficientMsg(true);
                setTimeout(() => {
                    setInsufficientMsg(false);
                    setActiveButtonId(null);
                }, 1500);
            } else if (response.data.message === "ok" && response.status === 200) {
                const { item, key, name, tel, uuid } = response.data.data;
                setShowForm({ ...showForm, formShow: true, item, key, name, tel, uuid });
            }
        } catch (error) {
            console.error("Error redeeming item:", error);
            setActiveButtonId(null);
        }
    };

    return (
        <>
            <SfxMainAudio />
            {showForm.formShow ? (
                <RedeemFormWeekly showForm={showForm} />
            ) : showForm.formConsentShow ? (
                <SubscribeConsentForm />
            ) : (
                <main className={styles.main} id="main">
                    <h1 className={styles.title}>Wallet</h1>
                    <p className={styles.subtitle}>Get your coins redeemed and play unlimited games!</p>
                    <section className={`${styles.insufficientCoins} ${styles.overlay} ${insufficientMsg ? styles.show : styles.hidden}`}>
                        <p>Insufficient Coins</p>
                    </section>
                    {loading && <p className={styles.loading}>Loading...</p>}
                    {error && <p className={styles.error}>{error}</p>}
                    {!loading && !error && (
                        <div className={styles.redeemGrid}>
                            {redeemItems.map((item) => (
                                <section key={item.id} className={styles.redeemCard}>
                                    <img className={styles.cardImg} src={item.image} alt={item.name} />
                                    <div className={styles.cardContent}>
                                        <h2 className={styles.cardTitle}>{item.name}</h2>
                                        <p className={styles.cardText}>{item.description.split("for")[0]}</p>
                                        <button
                                            value={item.id}
                                            className={`${styles.redeemButton} ${activeButtonId === item.id ? styles.activeButton : ''}`}
                                            onClick={handleClick}
                                            disabled={activeButtonId !== null}
                                        >
                                            {activeButtonId === item.id ? 'Please Wait' : 'Redeem'}
                                        </button>
                                    </div>
                                </section>
                            ))}
                        </div>
                    )}
                    <Footer />
                </main>
            )}
        </>
    );
}

export default React.memo(RedeemBodyWeekly);